import axios from 'axios';
import dayjs from 'dayjs';

interface Environment {
  mlURL: string;
  rubyURL: string;
  nodeURL: string;
  defaultCoupon: string;
  autocomplete: boolean;
  mlAPIKey: string;

  cognitoUserPoolId: string;
  cognitoUserPoolWebClientId: string;
  cognitoRegion: string;

  amplitudeAPIKey: string;
  stripeKey: string;

  timeout: number;
  splashTimeout: number;

  hotjarSideId: number;
  hotjarVersion: number;
  name: string;
}

const alpha: Environment = {
  mlURL: 'https://alpha.ml.iamyiam.com',
  rubyURL: 'https://alpha.app.iamyiam.com/api',
  nodeURL: 'https://alpha.app.iamyiam.com/api/v2',
  defaultCoupon: 'test_website_subscription',
  autocomplete: false,
  mlAPIKey: '12121212',

  cognitoUserPoolId: 'eu-west-2_A24fw6S6u',
  cognitoUserPoolWebClientId: '1f6ar791d6s1dk1mpar4rm1c0b',
  cognitoRegion: 'eu-west-2',

  amplitudeAPIKey: '2325935557c37c6717c3fee5e9ff479e',
  stripeKey: 'pk_test_BNYkNTn9O1FZZj33ETnYSQ4G',

  timeout: 2000,
  splashTimeout: 5000,

  hotjarSideId: 3906039,
  hotjarVersion: 6,
  name: 'alpha',
};

const prod: Environment = {
  mlURL: 'https://ml.iamyiam.com',
  rubyURL: 'https://www.iamyiam.com/api',
  nodeURL: 'https://www.iamyiam.com/api/v2',
  defaultCoupon: 'website_payment_subscription',
  autocomplete: false,
  mlAPIKey: 'a57858f1-9824-4a02-a2de-b4c756ae4805',

  cognitoUserPoolId: 'eu-west-2_6YqPkSazd',
  cognitoUserPoolWebClientId: '3de8prgg2khpp20b8gco312995',
  cognitoRegion: 'eu-west-2',

  amplitudeAPIKey: 'b204f9666a6102ed176606f2260b908c',
  stripeKey: 'pk_live_a2H2ivpmYZvwZAXv1FngUBL3',

  timeout: 2000,
  splashTimeout: 5000,

  hotjarSideId: 3905205,
  hotjarVersion: 6,
  name: 'production',
};

const prodNodeLocalhost = {
  ...prod,
  nodeURL: 'http://localhost:8000/api/v2',
};

const dev = {
  ...alpha,
  // ...prod,
  // nodeURL: 'http://localhost:8000/api/v2',
  autocomplete: true,
  timeout: 200,
  splashTimeout: 1000,
};

const alphaNodeLocalhost = {
  ...dev,
  nodeURL: 'http://localhost:8000/api/v2',
};

const alphaRubyLocalhost: Environment = {
  ...dev,
  rubyURL: 'http://localhost:3000/api',
};

const alphaLocalhost = {
  ...dev,
  nodeURL: 'http://localhost:8000/api/v2',
  rubyURL: 'http://localhost:3000/api',
};

export const environments = {
  alpha,
  dev,
  alphaNodeLocalhost,
  alphaRubyLocalhost,
  alphaLocalhost,

  prod,
  prodNodeLocalhost,
};

export const appEnvironment =
  environments[process.env.REACT_APP_ENVIRONMENT as keyof typeof environments];

export const mlClient = axios.create({
  baseURL: appEnvironment.mlURL,
});
export const rubyClient = axios.create({
  baseURL: appEnvironment.rubyURL,
});
export const nodeClient = axios.create({
  baseURL: appEnvironment.nodeURL,
});

export const autoCompleteData = {
  name: 'Lucian',
  email: 'test_new_website196@mailinator.com',
  lastName: 'Test',
  password: 'Password_1',
  phoneNumber: '7484219453',
  coupon: 'lite_membership_100',
  birthDate: dayjs().subtract(13, 'years'),

  address: {
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    city: 'London',
    postCode: 'SW2',
  },

  card: {
    cardNumber: '4242424242424242',
    cardExpiry: '12/25',
    cvc: '200',
    cardName: 'Lucian',
  },
};

export const ASSETS_URL = 'https://d3b3zp0qkrdpk.cloudfront.net';
