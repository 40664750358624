import { Box, Fade, Stack, Typography } from '@mui/material';
import { formStyles } from '../../onboarding/styles';
import QRCode from '../../common/QRCode';
import { useGetUserProfileQuery } from '../../api/api';
import { useSYDMessages } from '../../common/v2/hooks';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { useTranslation } from 'react-i18next';

export default function QRForm() {
  const { t } = useTranslation();
  const { data: user } = useGetUserProfileQuery();

  const { showMessages } = useSYDMessages();
  const hasShownMessagesRef = useRef(false);

  const [fadeIn, setFadeIn] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hasShownMessagesRef.current && user) {
      hasShownMessagesRef.current = true;
      dispatch(onboardingActions.clearQuestions());
      dispatch(onboardingActions.clearMessages());

      const messages = [
        t('welcome', { name: user.name }),
        t('iRecommendToProceed'),
        t('scanTheQR'),
        t('orYouCanContinueHere'),
      ];

      showMessages(messages).then(() => setFadeIn(true));
    }
  }, [dispatch, showMessages, t, user]);

  useEffect(() => {
    dispatch(onboardingActions.setButtonEnabled(true));
  }, [dispatch]);

  if (!user) {
    return null;
  }

  return (
    <Fade in={fadeIn}>
      <Box sx={formStyles.container}>
        <Stack
          justifyContent="center"
          alignItems="center"
          className="qr-container"
        >
          <QRCode />
        </Stack>

        <Typography className="mt-[20px] text-[#26262680] font-medium">
          {t('scanTheQRToLogin')}
        </Typography>
      </Box>
    </Fade>
  );
}
