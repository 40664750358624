import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/json/en.json';
import ja from './translations/json/ja.json';
import ar from './translations/json/ar.json';
import detector from 'i18next-browser-languagedetector';

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',

    resources: {
      en: {
        translation: en,
      },
      ja: {
        translation: ja,
      },
      ar: {
        translation: ar,
      },
    },
  });

export default i18next;
